'use strict'

import Polyfills from './Polyfills.js'
import { Bartender } from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'
import Swiper from 'swiper'
import { Navigation, Autoplay } from 'swiper/modules'
/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {
	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor(options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init off-canvas after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				el: '.bartender-main',
				contentEl: '.bartender-content',
			}, {
				focusTrap: true,
			})

			window.bartender.addBar('mobileBar', {
				el: '.mobileBar',
				position: 'right',
				mode: 'float',
			})

			document.querySelector('.toggleMobileBar').addEventListener('click', (event) => {
				window.bartender.toggle('mobileBar', event.target)
			})

			document.querySelector('.closeMobileBar').addEventListener('click', (event) => {
				window.bartender.close()
			})

			window.bartender.addPushElement('.toggleMobileBar', {
				positions: [
					'right',
				],
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu
					? mobileMenu.getAttribute('data-labels-open')
					: 'open',
				'menu.close': mobileMenu
					? mobileMenu.getAttribute('data-labels-close')
					: 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init(options = {}) {
		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initTabs()
		this.initAccordion()
		this.initTopicalAnnouncementSwiper()
		this.initSubMenuLoginLinks()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables() {
		document
			.querySelectorAll(this.options.responsiveTables.selector)
			.forEach((table) => {
				if (!table.closest('.table-wrapper')) {
					const tableWrapper = document.createElement('div')
					tableWrapper.classList.add('table-wrapper')
					tableWrapper.classList.add('overflow-x')
					table.parentNode.insertBefore(tableWrapper, table)
					tableWrapper.appendChild(table)
				}
			})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks() {
		const skipLinks = document.querySelectorAll(
			'.skip-link:not([data-skip-link])'
		)
		if (skipLinks.length) {
			const skipToBlur = (event) => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach((skipLink) => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', (event) => {
					const skipTo = document.getElementById(
						event.target.href.split('#')[1]
					)
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks() {
		// Parent of image links
		let parentNode = document.querySelector(
			this.options.imageLinks.parentSelector
		)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode
			.querySelectorAll(
				'a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]'
			)
			.forEach((link) => {
				if (!link.classList.contains('glightbox')) {
					if (
						!link.getAttribute('data-title') &&
						!link.getAttribute('data-glightbox')
					) {
						let figcaption = link.parentNode.querySelector('figcaption')
						if (figcaption) {
							let caption = figcaption ? figcaption.textContent : ''
							link.setAttribute('data-title', caption)
						}
					}
					link.classList.add('glightbox')
				}
			})

		// Initialize GLightbox
		if (parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	initTabs() {
		// Get all tab containers
		const tabContainers = document.querySelectorAll('.tabs')

		if(!tabContainers) return

		tabContainers.forEach(container => {
			const tabs = container.querySelectorAll('.tabs__button')

			tabs.forEach(tab => {
				tab.addEventListener('click', event => {
					event.preventDefault()

					const selectedTab = event.target
					const selectedPanelId = selectedTab.getAttribute('href')

					updateActiveTab(selectedTab, container)

					updateActivePanel(selectedPanelId, container)
				})
			})
		})

		function updateActiveTab(selectedTab, container) {
			container.querySelectorAll('.tabs__title').forEach(tab => {
				tab.classList.remove('tabs__title--active')
			})

			selectedTab.parentElement.classList.add('tabs__title--active')
		}

		function updateActivePanel(selectedPanelId, container) {
			container.querySelectorAll('.tabs__panel').forEach(panel => {
				panel.classList.remove('tabs__panel--active')
			})

			const selectedPanel = container.querySelector(selectedPanelId)
			if (selectedPanel) {
				selectedPanel.classList.add('tabs__panel--active')
			}
		}
	}

	/**
	 *
	 * Init  Accordion
	 */
	initAccordion () {
		const accordionHeaders = document.querySelectorAll(
			'[data-accordion-header]'
		)
		if (!accordionHeaders.length) return

		// Check if viewport width is less than 1024px
		if (window.innerWidth < 1024) {
			Array.prototype.slice.call(accordionHeaders, 1).forEach((accordionHeader) => {
				let target = document.getElementById(
					accordionHeader.getAttribute('aria-controls')
				)
				if (target) {
					target.setAttribute('hidden', true)
				}
			})
		}
		// Add expand event to accordion buttons
		Array.prototype.forEach.call(accordionHeaders, (accordionHeader) => {
			let target = document.getElementById(
				accordionHeader.getAttribute('aria-controls')
			)
			if (target) {
				accordionHeader.onclick = () => {
					let expanded =
					accordionHeader.getAttribute('aria-expanded') == 'true' || false
					accordionHeader.setAttribute('aria-expanded', !expanded)
					// Toggle 'hidden' attribute instead of class
					if (target.hasAttribute('hidden')) {
						target.removeAttribute('hidden')
					} else {
						target.setAttribute('hidden', true)
					}
				}
			}
		})
	}

	/**
	 *
	 * Init Topical Announcement Swiper
	 */
	initTopicalAnnouncementSwiper() {
		const swiper = new Swiper('.topical-announcements-swiper', {
			modules: [Navigation, Autoplay],
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 24,
			watchOverflow: true,
			simulateTouch: true,
			rewind: false,
			loop: true,
			centeredSlides: false,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			pauseOnMouseEnter: true,
			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
				clickable: true,
			},
		})
	}

	/**
	 * Init sub menu login links
	 */
	initSubMenuLoginLinks() {
		const subMenuLoginLinks = document.querySelectorAll('a.sub-menu__item--locked')
		if (!subMenuLoginLinks.length) return

		const loginPageInfo = document.getElementById('login-page-info')
		if (!loginPageInfo) return

		subMenuLoginLinks.forEach(link => {
			const classes = link.getAttribute('class')
			const IDFromClass = classes.match(/sub-menu__item--page-id-(\d+)/)
			const pageID = IDFromClass ? IDFromClass[1] : null
			if (!pageID) return
			link.setAttribute('href', loginPageInfo.getAttribute('data-url') + '?p=' + pageID)
		})
	}

}
