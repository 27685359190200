import '../css/styles.scss'
import Site from '../lib/Site.js'

import '@fontsource/oxygen/latin-400.css'
import '@fontsource/oxygen/latin-700.css'
import '@fontsource/source-sans-pro/300.css'
import '@fontsource/source-sans-pro/400.css'
import '@fontsource/source-sans-pro/600.css'
import '@fontsource/source-sans-pro/700.css'
import '@fontsource/source-sans-pro/900.css'

if (document.body.id !== 'form-builder-form') {
	window._site = new Site()
}
